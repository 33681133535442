import { Link } from "@chakra-ui/react";
import { Record } from "datocms-structured-text-utils";
import { StructuredTextPropTypes } from "react-datocms";


const renderInlineRecord: StructuredTextPropTypes<Record>["renderInlineRecord"] =
  (inlineRecord) => (
    <Link color="tomato" href="https://www.google.fr/">
      Placeholder inline record: {inlineRecord.record.id}
    </Link>
  );

export default renderInlineRecord;
