import { Heading } from "@chakra-ui/react";
import { renderRule, isHeading } from "datocms-structured-text-utils";
import React from "react";

const styles = {
  "1": {
    variant: "heading-1",
    size: "xl",
  },
  "2": {
    variant: "heading-2",
    size: "lg",
  },
  "3": {
    variant: "heading-3",
  },
  "4": {
    variant: "heading-4",
  },
  "5": undefined,
  "6": undefined,
} as const;

const heading = renderRule(isHeading, ({ node, children, key }) => (
  <Heading
    as={`h${node.level}`}
    key={key}
    marginBottom="6"
    {...styles[node.level]}
  >
    {children}
  </Heading>
));

export default heading;
