import { Record } from "datocms-structured-text-utils";
import React from "react";
import { StructuredTextPropTypes } from "react-datocms";
import DatoCmsImage from "src/blocks/DatoCmsImage";
import Placeholder from "src/components/__dev__/Placeholder";

const BLOCKS = {
  DatoCmsImage,
};

const isRegisteredBlock = (typename: string): typename is keyof typeof BLOCKS =>
  typename in BLOCKS;

/**
 * Given a block's data, render its corresponding components or a placeholder if we don't have one
 */
const renderBlock: StructuredTextPropTypes<Record>["renderBlock"] = (block) => {
  const Block = isRegisteredBlock(block.record.__typename)
    ? BLOCKS[block.record.__typename]
    : null;

  if (Block) return <Block {...(block.record as any)} />;

  return (
    <Placeholder title={`Rendering Block ${block.record.id}`}>
      {block}
    </Placeholder>
  );
};

export default renderBlock;
