import { Record } from "datocms-structured-text-utils";
import { StructuredTextPropTypes } from "react-datocms";

const renderText: StructuredTextPropTypes<Record>["renderText"] = (text) => {
  /**
   * Here we could alter the text content coming from DatoCMS if needed.
   */
  return text;
};

export default renderText;
