import { Record } from "datocms-structured-text-utils";
import React, { FC } from "react";
import {
  StructuredText as DatoStructuredText,
  StructuredTextPropTypes,
} from "react-datocms";

import {
  renderBlock,
  renderInlineRecord,
  renderLinkToRecord,
  renderText,
} from "./lib";
import rules from "./rules";

export type StructuredTextType<R extends Record = Record> = {
  /** A DatoCMS compatible document */
  value: Document | undefined;
  /** Blocks associated with the Structured Text */
  blocks?: readonly any[];
  /** Links associated with the Structured Text */
  links?: R[];
};

type StructuredTextProps = {
  customRules?: StructuredTextPropTypes<Record>["customRules"];
  data: StructuredTextPropTypes<Record>["data"] | StructuredTextType<Record>;
};

const StructuredText: FC<StructuredTextProps> = ({
  data,
  customRules = [],
}) => (
  <DatoStructuredText
    customRules={[...customRules, ...rules]}
    data={data as StructuredTextPropTypes<Record>["data"]}
    renderBlock={renderBlock}
    renderInlineRecord={renderInlineRecord}
    renderLinkToRecord={renderLinkToRecord}
    renderText={renderText}
  />
);

export default StructuredText;
