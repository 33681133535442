import {
  chakra,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";
import Picture from "src/components/Picture";

const DatoCmsImage: FC<Gatsby.ImageFragment> = ({ media }) => {
  const disclosure = useDisclosure();

  return (
    <>
      <Modal {...disclosure} size="xl">
        <ModalOverlay opacity={0.9} />

        <ModalContent overflow="hidden">
          <Picture image={media?.full} />

          <ModalCloseButton
            _hover={{ bg: "gray.700" }}
            bg="gray.800"
            color="white"
          />
        </ModalContent>
      </Modal>

      <chakra.button float="left" mb={6} mr={6} onClick={disclosure.onOpen}>
        <Picture image={media?.medaillon} />
      </chakra.button>
    </>
  );
};

export default DatoCmsImage;
