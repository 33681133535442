import { Link } from "@chakra-ui/react";
import { Record } from "datocms-structured-text-utils";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { StructuredTextPropTypes } from "react-datocms";

const renderLinkToRecord: StructuredTextPropTypes<Record>["renderLinkToRecord"] =
  (link) => (
    <Link
      as={GatsbyLink}
      color="tomato"
      fontWeight="bold"
      to={`/${link.record.slug}`}
    >
      {link.children}
    </Link>
  );

export default renderLinkToRecord;
